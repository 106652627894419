<template>
  <div class="page">
    <v-img class="logo" contain :src="logo" max-height="150" max-width="250"></v-img>
    <div v-if="!sent" class="page-content">
      <h2 class="explain-text">{{$t("pageTitle")}}</h2>
      <v-row class="text-center faces">
        <v-col cols="4" class="face">
          <v-icon :color="selected == 3 ? 'red' : 'gray'" size="72" @click="selected = 3"> mdi-emoticon-sad-outline </v-icon>
          <span :class="{'face-text': true, 'text-red': selected == 3}" style=""> #{{$t("angry")}}</span>
        </v-col>
        <v-col cols="4" class="face">
          <v-icon :color="selected == 2 ? 'orange' : 'gray'" size="72" @click="selected = 2"> mdi-emoticon-neutral-outline </v-icon>
          <span :class="{'face-text': true, 'text-orange': selected == 2}">#{{$t("neutral")}}</span>
        </v-col>
        <v-col cols="4" class="face">
          <v-icon :color="selected == 1 ? 'green' : 'gray'" size="72" @click="selected = 1"> mdi-emoticon-happy-outline </v-icon>
          <span :class="{'face-text': true, 'text-green': selected == 1}">#{{$t("happy")}}</span>
        </v-col>
      </v-row>
      <v-divider inset></v-divider>
      <v-textarea outlined name="moreInfos" v-model="moreInfos" :label="$t('messageInputLabel')" value="" class="input"></v-textarea>
      <v-btn color="#3C3C3C" elevation="2" dark class="button"  @click="send()">{{$t("sendButtonLabel")}}</v-btn>
    </div>
    <div v-else class="page-content thank-you">
      <h2 class="explain-text">{{$t("thankYouMessage")}}</h2>
      <lottie-animation :loop="false" path="./success.json" />
    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
const mqtt = require('mqtt')
import {getConfig} from "@/assets/js/Utils"
import {getDemoById, getLogoUrl} from "@/assets/js/Demo"

export default {
  name: 'FeedBackPage',
  components: {
      LottieAnimation
  },
  data: () => ({
    selected : 0,
    moreInfos: "",
    sent: false,
    client: null,
    terminalId: null,
    loadedDemo: null,
    logo: require("@/assets/img/logo_worldline.png")
  }),
  created(){
    this.terminalId = this.$route.query.terminalId;
    if(this.$route.query.demoId){
      getDemoById(this.$route.query.demoId, response => {
        if(!response.data.error && response.data != null && response.data != ""){
          this.loadedDemo = response.data
          this.logo = getLogoUrl(this.loadedDemo.logo)
          this.$i18n.locale = this.loadedDemo.lang
          document.getElementById("favicon").href = getLogoUrl(this.loadedDemo.logo);
          document.getElementById("title").text = this.loadedDemo.brandName.split("_").join(" ").replace(/[0-9]/g, "").toUpperCase() + " " + this.$t("metaTitle");
        }
      })
    }
    if( this.terminalId ){        
      let mqttConfig = {
        host: getConfig().MQTT_SERVER,
        username: getConfig().MQTT_USER,
        password: getConfig().MQTT_PWD,
        protocol: getConfig().MQTT_PROTOCOL,
        port: getConfig().MQTT_PORT,
        rejectUnauthorized: false
      }
      this.client  = mqtt.connect(mqttConfig)

      this.client.on('connect', () => {
        this.client.publish("rd/chatbot/connectedKitchenBot/" + this.terminalId + "/feedback/close", "")
      })
    }else{
      console.log("no terminal found")
    }


  },
  methods:{
    send(){
      if(this.client !== null){
        setTimeout(() => this.sent = true, 0)
      }
      
    }
  }
};
</script>

<style>
  .page{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }
  .page-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction:column;
    margin: 16px;
    width: 80%;
  }
  .logo{
    margin: 32px 0;
  }
  .faces{
    margin-bottom: 32px;
  }
  .faces .face{
    display: flex;
    flex-direction: column;
  }
  .face-text{
    font-weight: bold;
    color: gray;
  }
  .text-green{
    color: green;
  }
  .text-orange{
    color: orange;
  }
  .text-red{
    color: red;
  }

  .explain-text{
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
    margin: 8px 2px 32px;
  }

  .input{
    width: 100%;
  }
  .button{
    align-self: flex-end;
  }

</style>
