var config = {
    CUSTO_URL: "https://wldd-yumi-demonstrations-wldd-tools.pubqlf.co.as8677.net",
    MQTT_SERVER: "mqtt.rd.atosworldline.com",
    MQTT_USER: "heptagone",
    MQTT_PWD: "hept@g0ne",
    MQTT_PORT: "8003",
    MQTT_PROTOCOL: "wss",
};

export function getConfig(){
	return config;
}