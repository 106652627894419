var axios = require('axios');
import {getConfig} from "./Utils";

var app = getConfig();

export function getDemoById(demoId, callback){
	var path = "/demonstration/getById?demoId=" + demoId;
	var url = app.CUSTO_URL + path;

	axios.get(url)
		.then(function (response) {
			if (response.status == 200) {
				callback(response)
			}
		})
		.catch(function (error) {
			console.error("Error :" + error);
		});
}
export function getLogoUrl(logoPath){
	return `${app.CUSTO_URL}/demonstration/get/logo/${logoPath}`
}